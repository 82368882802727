import {
  columnsNames,
  dbEditKeys,
  discountKeys,
  sampleInfoKeys,
} from "./constant";
import SelectDropDown from "../Components/SelectDropdown/SelectDropDown";
import styles from "../sample_strategy.module.css";
import {
  calculateTotalDiscountOnTable,
  calculateTotalSampleCost,
} from "./fieldsCalculationsFormulas";
import CustomInputField from "../Components/CustomInputField/CustomInputField";
import CustomTextArea from "../Components/CustomTextArea/CustomTextArea";
import CustomSelectEditor from "../../../components/CustomSelects";
import CustomFilters from "./CustomSetFilter";
import SnackBar from "./snackbar";

export const formatCurrencyInput = (value) => {
  // Remove any non-numeric characters except for the decimal point
  const cleanedValue = value.replace(/[^0-9]/g, "");
  const maxDigits = 8;
  if (cleanedValue.length > maxDigits) {
    return cleanedValue.slice(0, maxDigits);
  }

  // Split the cleaned value into integer and decimal parts
  let integerPart = cleanedValue.slice(0, 5); // First 5 digits
  let decimalPart = cleanedValue.slice(5, 7); // Next 2 digits
  let newValue = integerPart;
  if (decimalPart) {
    newValue = integerPart + "." + decimalPart;
  }

  return newValue;
};

export const formatNumber = (value) => {
  // Remove currency symbol if it exists and parse the value as a float

  const number = parseFloat(value.replace(/[^\d.-]/g, ""));

  // Check if the value is exactly "00.00"
  if (number === 0) {
    return `${value}`; // Return the original value with $ if it's "00.00"
  }

  // If the number has a decimal part, format it with commas for the integer part
  if (number % 1 !== 0) {
    const [integerPart, decimalPart] = number.toFixed(2).split("."); // Ensure 2 decimal places

    // Format the integer part with commas
    const formattedInteger = parseInt(integerPart).toLocaleString("en-IN");

    // Return the formatted integer part with the decimal part intact and prepend $
    return `$${formattedInteger}.${decimalPart}`;
  }

  // Format the integer (no decimal part) with commas and prepend $
  return `$${number.toLocaleString("en-IN")}`;
};

export const compareSampleReports = (oldReport, newReport) => {
  const isDragged = newReport?.report_header?.is_dragged;
  const reportMap = new Map(oldReport.map((item) => [item.id, item]));

  const roomPositions = new Map();
  oldReport.forEach((item, index) => {
    if (!roomPositions.has(item.room_name.name)) {
      roomPositions.set(item.room_name.name, index);
    }
  });

  newReport?.sample_strategy_report.forEach((newItem) => {
    if (reportMap.has(newItem.id)) {
      // Update the observation name if it has changed
      const existingItem = reportMap.get(newItem.id);
      if (existingItem.observation.name !== newItem.observation.name) {
        existingItem.observation.name = newItem.observation.name;
      }
    } else {
      const roomIndex = roomPositions.get(newItem.room_name.name);
      if (roomIndex !== undefined) {
        oldReport.splice(roomIndex + 1, 0, newItem);
      } else {
        oldReport.push(newItem);
      }
      reportMap.set(newItem.id, newItem);
    }
  });

  // Remove items from reportMap that are not in newReport
  const newReportIds = new Set(
    newReport?.sample_strategy_report.map((item) => item.id)
  );
  Array.from(reportMap.keys()).forEach((id) => {
    if (!newReportIds.has(id)) {
      reportMap.delete(id);
    }
  });

  // If dragged, return the updated list without grouping/sorting
  if (isDragged) {
    return Array.from(reportMap.values());
  }

  // Group by room_name.name
  const groupedReports = Array.from(reportMap.values()).reduce(
    (groups, item) => {
      const roomName = item.room_name.name;
      if (!groups[roomName]) {
        groups[roomName] = [];
      }
      groups[roomName].push(item);
      return groups;
    },
    {}
  );

  // Sort items by observation.name according to the order in oldReport
  const sortedReport = Object.keys(groupedReports).reduce(
    (result, roomName) => {
      const roomItems = groupedReports[roomName];
      const sortedRoomItems = roomItems.sort((a, b) => {
        const oldReportIndexA = oldReport.findIndex(
          (item) => item.observation.name === a.observation.name
        );
        const oldReportIndexB = oldReport.findIndex(
          (item) => item.observation.name === b.observation.name
        );

        return (
          (oldReportIndexA === -1 ? Infinity : oldReportIndexA) -
          (oldReportIndexB === -1 ? Infinity : oldReportIndexB)
        );
      });
      result.push(...sortedRoomItems);
      return result;
    },
    []
  );

  return sortedReport;
};

export const CustomTestDropDown = (
  options,
  onUpdate,
  props,
  rowIndex,
  data
) => {
  return (
    <SelectDropDown
      id={rowIndex}
      placeholder="Select .."
      options={options}
      handleOnChange={(e) => {
        const updatedTest = {
          id: e.value,
          name: e.label,
        };
        onUpdate(updatedTest);
      }}
      searchValue={options.find(
        (option) => Number(option.value) === Number(props.defaultValue.id)
      )}
      isSearchable={false}
      isClearable={false}
    />
  );
};

export const CustomDiscountDropDown = ({
  value,
  stopEditing,
  values = [],
  api,
  node,
  column,
}) => {
  const handleChange = (selectedOption) => {
    if (selectedOption) {
      api.getRowNode(node.id).setDataValue(column.colId, selectedOption);
    }
    stopEditing();
  };

  return (
    <SelectDropDown
      placeholder="Select .."
      options={values}
      handleOnChange={handleChange}
      searchValue={values.find((option) => option.value === value) || null}
      isSearchable={false}
      isClearable={false}
    />
  );
};

export const CustomReasonDropDown = (
  options,
  onUpdate,
  props,
  rowIndex,
  data
) => {
  return (
    <SelectDropDown
      placeholder="Select .."
      options={options}
      handleOnChange={(e) => {
        const updatedReason = {
          id: e.value,
          name: e.label,
        };
        onUpdate(updatedReason);
      }}
      searchValue={options.find(
        (option) => Number(option.value) === Number(props.defaultValue.id)
      )}
      isSearchable={false}
      isClearable={false}
    />
  );
};

const CustomHeader = (
  params,
  handleHiddenColumn,
  isDisplayHiddenColumns,
  flag
) => {
  const { column } = params;

  return (
    <div className="cost-custom-header">
      <span>{column.colDef.headerName}</span>
      {(isDisplayHiddenColumns || flag) && (
        <img
          src={require("assets/icons/column_icon.png").default}
          alt="icon"
          className="header-icon"
          onClick={() => handleHiddenColumn()}
          style={{ cursor: "pointer", marginLeft: "8px" }}
        />
      )}
    </div>
  );
};

const customCellForFlagFormatter = (cell, row, handleFlagSelection) => {
  return (
    <img
      className={styles.icon}
      src={
        row[columnsNames.client_approval.value]
          ? require("assets/icons/flagchecked.png").default
          : require("assets/icons/flagunchecked.png").default
      }
      alt={row[columnsNames.client_approval.value] ? "Active" : "Inactive"}
      onClick={() => handleFlagSelection(row)}
    />
  );
};

const addRowNumber = (data) => {
  data?.sample_strategy_report.forEach((row, index) => {
    row.row_number = index + 1;
  });
};

const handleSampleLocationAndLabCode = (data) => {
  data?.sample_strategy_report.forEach((row) => {
    if (!row.sample_info) {
      row.sample_info = [];
    }

    const hasSampleLocation = row.sample_info.some(
      (info) => info.name === sampleInfoKeys.SAMPLE_LOCATION.name
    );

    const hasLabCode = row.sample_info.some(
      (info) => info.name === sampleInfoKeys.LAB_CODE.name
    );

    if (!hasSampleLocation) {
      row.sample_info.push({ ...sampleInfoKeys.SAMPLE_LOCATION });
    }

    if (!hasLabCode) {
      row.sample_info.push({ ...sampleInfoKeys.LAB_CODE });
    }
  });
};

const handleNotes = (data) => {
  data.sample_strategy_report = data.sample_strategy_report.map((row) =>
    !row.sample_note
      ? {
          ...row,
          sample_note: "",
        }
      : row
  );
};

export const markRowsAndColFalse = (data) => {
  data?.sample_strategy_report.forEach((row) => {
    row[dbEditKeys.rowEdit] = false;
    if (row.room_name) {
      row.room_name[dbEditKeys.colEdit] = false;
    }
    if (row.observation) {
      row.observation[dbEditKeys.colEdit] = false;
    }
    if (row.test) {
      row.test[dbEditKeys.colEdit] = false;
    }
    if (row.sample_info?.[0]) {
      row.sample_info[0][dbEditKeys.colEdit] = false;
    }
    if (row.sample_info?.[1]) {
      row.sample_info[1][dbEditKeys.colEdit] = false;
    }
    if (row.test_sample) {
      row.test_sample[dbEditKeys.colEdit] = false;
    }
  });
};

export const markRowsAndColTrue = (data) => {
  data?.sample_strategy_report.forEach((row) => {
    row[dbEditKeys.rowEdit] = true;
    row.room_name[dbEditKeys.colEdit] = true;
    row.observation[dbEditKeys.colEdit] = true;
    row.test[dbEditKeys.colEdit] = true;
    row.sample_info[0][dbEditKeys.colEdit] = true;
    row.sample_info[1][dbEditKeys.colEdit] = true;
    row.test_sample[dbEditKeys.colEdit] = true;
  });
};

export const handleEmptyColumns = (data) => {
  /* add row number */
  addRowNumber(data);
  /* add row number */

  /* handle Sample Location and Lab code */
  handleSampleLocationAndLabCode(data);
  /* handle Sample Location and Lab code */

  handleNotes(data);

  /* mark all rows and col false */
  markRowsAndColFalse(data);
  /* mark all rows and col false */
};

export const getNestedValue = (obj, path) => {
  const keys = path.split(".");
  return keys.reduce(
    (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
    obj
  );
};

export const setNestedValue = (obj, path, value) => {
  const keys = path.split(".");
  keys.reduce((acc, key, index) => {
    if (index === keys.length - 1) {
      acc[key] = value;
    }
    return acc[key] !== undefined ? acc[key] : (acc[key] = {});
  }, obj);
};

export const setColumnEditTrue = (key, row) => {
  if (key === columnsNames.room.value) {
    row.room_name[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.observation.value) {
    row.observation[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_type.value) {
    row.test[dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_location.value) {
    row.sample_info[0][dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_note.value) {
    row.sample_info[0][dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.lab_code.value) {
    row.sample_info[1][dbEditKeys.colEdit] = true;
  }
  if (key === columnsNames.sample_cost.value) {
    row.test_sample[dbEditKeys.colEdit] = true;
  }
};

const handleRoomUpdateLogic = (row, data) => {
  let updatedRoom = "";
  updatedRoom = row?.room_name;

  data.forEach((li) => {
    if (li.room_name?.id === updatedRoom.id) {
      li.room_name = JSON.parse(JSON.stringify(updatedRoom));
      li.room_name[dbEditKeys.colEdit] = true;
      li[dbEditKeys.rowEdit] = true;
    }
  });
};

const handleObservationUpdateLogic = (row, data) => {
  let updatedObservation = "";
  updatedObservation = row?.observation;

  data.forEach((li) => {
    if (li.observation_id === row.observation_id) {
      li.observation = JSON.parse(JSON.stringify(updatedObservation));
      li.observation[dbEditKeys.colEdit] = true;
      li[dbEditKeys.rowEdit] = true;
    }
  });
};

const handleTestUpdateLogic = (row, data, testList) => {
  const selectedTest = row?.test;
  const selectedTestSampleCost = testList.find(
    (test) => Number(test.id) === Number(selectedTest.id)
  );
  if (
    selectedTestSampleCost &&
    selectedTestSampleCost?.test_samples.length > 0
  ) {
    const cost = selectedTestSampleCost?.test_samples[0]?.cost;
    const sample_id = selectedTestSampleCost?.test_samples[0]?.id;
    data.forEach((li) => {
      if (li?.id === row.id) {
        li.test.sample_id = sample_id;
        li.test_sample.cost = cost;
        li.test_sample[dbEditKeys.colEdit] = true;
        li[dbEditKeys.rowEdit] = true;
      }
    });
  }
};

export const handleSampleCostUpdateLogic = (data, summary) => {
  const totalSampleCost = calculateTotalSampleCost(data);
  summary.total_sample_cost = totalSampleCost.toFixed(2);
};

export const handleDiscountUpdateLogic = (data, summary, discount) => {
  const sampleDiscount = calculateTotalDiscountOnTable(data);
  discount[discountKeys.SAMPLE_DISCOUNT] = sampleDiscount.toFixed(2);
  const totalDiscount =
    Number(sampleDiscount) + Number(discount[discountKeys.OTHER_DISCOUNT]);
  summary.total_discount = totalDiscount.toFixed(2);
};

export const handleCustomValidationAndUpdateColumnOnDemand = ({
  key,
  row,
  sample_strategy_report,
  testList,
  summary,
  discount,
}) => {
  switch (key) {
    case columnsNames.room.value:
      handleRoomUpdateLogic(key, row, sample_strategy_report);
      return;
    case columnsNames.observation.value:
      handleObservationUpdateLogic(key, row, sample_strategy_report);
      return;
    case columnsNames.sample_type.value:
      handleTestUpdateLogic(key, row, sample_strategy_report, testList);
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      return;
    case columnsNames.sample_cost.value:
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      return;
    case columnsNames.discount.value:
      handleDiscountUpdateLogic(sample_strategy_report, summary, discount);
      handleSampleCostUpdateLogic(sample_strategy_report, summary);
      return;

    default:
      return null;
  }
};

const costFormatter = (value) => {
  return <span className="strike-through">{`$${value || 0}`}</span>;
};

const handleHideSelection = (params, handleHideRow) => {
  const row = params.data;
  return (
    <div className={styles.hideCnt} onClick={() => handleHideRow(row)}>
      {row?.is_hidden ? (
        <img
          className={styles.unhideIcon}
          src={require("assets/icons/hide.png").default}
          alt="icon"
        />
      ) : (
        <img
          className={styles.hideIcon}
          src={require("assets/icons/un-hide.png").default}
          alt="icon"
        />
      )}
    </div>
  );
};

const handleRowSelection = (params, handleSelectRow) => {
  const row = params.data;
  return (
    <div
      className={"checkInputCnt"}
      onClick={() => {
        handleSelectRow(row);
      }}
    >
      <input
        className={"select_checkbox"}
        type="checkbox"
        checked={row[columnsNames.status.value]}
      />
    </div>
  );
};

const handleAllCheck = (selectAllRows, handleAllSelectRow) => {
  return (
    <div className="checkInputCnt">
      <input
        className="select_checkbox"
        style={{ marginTop: "0px !important" }}
        type="checkbox"
        checked={selectAllRows}
        onChange={(e) => handleAllSelectRow(e)}
      />
    </div>
  );
};

const handleCopyRow = (event) => {
  setTimeout(() => {
    const selectedRows = event.api.getSelectedRows();
    if (selectedRows.length === 0) {
      console.warn("No rows selected!");
      return;
    }

    // Required columns and their mapped data paths
    const requiredColumns = [
      { field: "row_number", getValue: (row) => row.row_number }, // S.No
      { field: "room", getValue: (row) => row.room_name?.name || "" }, // Room
      { field: "observation", getValue: (row) => row.observation?.name || "" }, // Observation
      { field: "sample_type", getValue: (row) => row.test?.name || "" }, // Sample Type
      {
        field: "sample_location",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Sample Location")
            ?.value || "",
      }, // Sample Location
      {
        field: "lab_code",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Lab Code")?.value ||
          "",
      }, // Lab Code
      { field: "sample_cost", getValue: (row) => row.test_sample?.cost || "" }, // Sample Cost
      { field: "discount", getValue: (row) => row.discount?.name || "" }, // Discount
      {
        field: "sample_reason",
        getValue: (row) => row.sample_reason?.name || "",
      }, // Sample Reason
      { field: "sample_note", getValue: (row) => row.sample_note || "" }, // Note
    ];

    // Convert row data into a tab-separated format for Excel
    const copiedData = selectedRows
      .map(
        (row) =>
          requiredColumns
            .map(({ getValue }) => getValue(row)) // Extract values using helper function
            .join("\t") // Tab-separated for Excel columns
      )
      .join("\n"); // Newline-separated for rows

    console.log("Formatted Data:", copiedData); // Debugging

    // Copy data to clipboard
    navigator.clipboard
      .writeText(copiedData)
      .then(() => console.log("Data copied successfully!"))
      .catch((err) => console.error("Clipboard copy failed:", err));
  }, 50);
};

const handleCopyAllRows = (event) => {
  setTimeout(() => {
    let allRows = [];

    event.api.forEachNode((node) => {
      allRows.push(node.data); // Collect all row data
    });

    if (allRows.length === 0) {
      console.warn("No rows available to copy!");
      return;
    }

    // Required columns and their mapped data paths
    const requiredColumns = [
      { field: "row_number", getValue: (row) => row.row_number }, // S.No
      { field: "room", getValue: (row) => row.room_name?.name || "" }, // Room
      { field: "observation", getValue: (row) => row.observation?.name || "" }, // Observation
      { field: "sample_type", getValue: (row) => row.test?.name || "" }, // Sample Type
      {
        field: "sample_location",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Sample Location")
            ?.value || "",
      }, // Sample Location
      {
        field: "lab_code",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Lab Code")?.value ||
          "",
      }, // Lab Code
      { field: "sample_cost", getValue: (row) => row.test_sample?.cost || "" }, // Sample Cost
      { field: "discount", getValue: (row) => row.discount?.name || "" }, // Discount
      {
        field: "sample_reason",
        getValue: (row) => row.sample_reason?.name || "",
      }, // Sample Reason
      { field: "sample_note", getValue: (row) => row.sample_note || "" }, // Note
    ];

    // Convert row data into a tab-separated format for Excel
    const copiedData = allRows
      .map(
        (row) =>
          requiredColumns
            .map(({ getValue }) => getValue(row)) // Extract values using helper function
            .join("\t") // Tab-separated for Excel columns
      )
      .join("\n"); // Newline-separated for rows

    console.log("Formatted Data:", copiedData); // Debugging

    // Copy data to clipboard
    navigator.clipboard
      .writeText(copiedData)
      .then(() => console.log("All data copied successfully!"))
      .catch((err) => console.error("Clipboard copy failed:", err));
  }, 50);
};

const handleSrHeader = (event) => {
  return (
    <h6
      style={{ fontWeight: 700, fontSize: 14, margin: "10px 0" }}
      onClick={() => handleCopyAllRows(event)}
    >
      S.No
    </h6>
  );
};

const handleTextLengthValidation = ({
  row,
  newValue,
  column,
  validationError,
  setValidationError,
  maxLength = 50,
  isRequired = true,
}) => {
  if (!newValue && isRequired) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} is required`,
      },
    });
    return {
      valid: false,
      message: `${column.text} is required`,
    };
  } else if (newValue.length > maxLength) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} must be ${maxLength} characters or less`,
      },
    });
    return {
      valid: false,
      message: `${column.text} must be ${maxLength} characters or less`,
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const handleRoomValidation = ({
  row,
  newValue,
  column,
  validationError,
  setValidationError,
  maxLength = 50,
  isRequired = true,
  data,
}) => {
  const roomObj = {
    id: row.room_name?.id,
    name: newValue,
  };
  const isDuplicate = data.find(
    (li) =>
      li.room_name?.id !== roomObj.id &&
      li.room_name.name.trim().toLowerCase() ===
        roomObj.name.trim().toLowerCase()
  );
  if (!newValue && isRequired) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} is required`,
      },
    });
    return {
      valid: false,
      message: `${column.text} is required`,
    };
  } else if (newValue.length > maxLength) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `${column.text} must be ${maxLength} characters or less`,
      },
    });
    return {
      valid: false,
      message: `${column.text} must be ${maxLength} characters or less`,
    };
  } else if (isDuplicate) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: `Room already exists in line number ${isDuplicate.row_number}`,
      },
    });
    return {
      valid: false,
      message: `Room already exists in line number ${isDuplicate.row_number}`,
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const handlePriceValidation = (
  newValue,
  row,
  column,
  validationError,
  setValidationError
) => {
  const regex = /^\d{1,5}(\.\d{1,2})?$/; // Up to 5 digits before decimal and 2 digits after

  if (!newValue) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: "Cost is required",
      },
    });
    return {
      valid: false,
      message: "Cost is required",
    };
  } else if (isNaN(newValue) || !regex.test(newValue)) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message:
          "Cost should be numeric with up to 5 digits before decimal and 2 digits after",
      },
    });
    return {
      valid: false,
      message:
        "Cost should be numeric with up to 5 digits before decimal and 2 digits after",
    };
  } else if (newValue <= 0) {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: true,
        message: "Cost should be greater than 0",
      },
    });
    return {
      valid: false,
      message: "Cost should be greater than 0",
    };
  } else {
    setValidationError({
      ...validationError,
      [column.dataField]: {
        error: false,
        message: "",
      },
    });
  }
  return true;
};

const roomColumnDynamicStyle = (isDisplayHiddenColumns, error) => {
  let errorWidth = error ? "40" : "0";
  const normalWidth = isDisplayHiddenColumns ? "150px" : "200px";
  return `calc(${normalWidth} + ${errorWidth}px)`;
};

const handleCellEdit = (params) => {
  // handleRowUpdateAction(params);
};

export const getColumnsOfReportTable = ({
  testListOptions = [],
  isDisplayHiddenColumns = true,
  handleHiddenColumn = () => {},
  discountOptions = [],
  sampleReasonOptions = [],
  handleFlagSelection = () => {},
  handleSelectRow = () => {},
  handleAllSelectRow = () => {},
  selectAllRows,
  handleHideRow = () => {},
  handleUpdateRow = () => {},
  validationError,
  setValidationError,
  activeIndex,
  selectedOptions,
  setSelectedOptions,
  data,
}) => {
  console.log("activeIndex---", activeIndex);

  const columns = [
    {
      headerName: columnsNames.id.name,
      field: columnsNames.id.value,
      hide: true,
      editable: false,
      width: 60,
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerComponent: (event) => <SnackBar event={event} />,
      headerName: columnsNames.row_number.name,
      valueGetter: (params) => params.node.rowIndex + 1,
      editable: false,
      width: 75,
      filter: false,
      sortable: false,
      suppressMovable: true,
      onCellClicked: (event) => {
        const isSelected = event.node.isSelected();
        event.node.setSelected(!isSelected);
        handleCopyRow(event);
      },

      cellStyle: { textAlign: "center" },
    },

    selectedOptions.length === 0
      ? {
          headerName: "",
          field: "drag",
          rowDrag: true,
          rowDragMultiRow: true,
          width: 55,
          sortable: false,
          filter: false,
          suppressMovable: true,
          suppressMoveWhenRowDragging: true,
        }
      : {
          headerName: "",
          field: "",
          width: 55,
          editable: false,
          sortable: false,
          filter: false,
          suppressMovable: true,
          cellRenderer: (params) => <div className="drag-cnt"></div>,
        },
    {
      headerName: columnsNames.is_hidden.name,
      field: columnsNames.is_hidden.value,
      cellRenderer: (params) => handleHideSelection(params, handleHideRow),
      width: 55,
      sortable: false,
      filter: false,
      editable: false,
    },
    {
      headerComponent: (params) =>
        handleAllCheck(selectAllRows, handleAllSelectRow, activeIndex),
      headerName: "",
      field: columnsNames.status.value,
      cellRenderer: (params) =>
        handleRowSelection(params, handleSelectRow, activeIndex),
      width: 55,
      editable: false,
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: columnsNames.room.name,
      field: columnsNames.room.value,
      editable: true,
      cellEditorParams: { type: "text", maxLength: 50 },
      ...(isDisplayHiddenColumns ? { flex: 1 } : { width: 200, minWidth: 200 }),
      cellStyle: { textAlign: "left" },
      valueFormatter: (params) => params.value || "-",
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
      },

      sortable: false,
      suppressMovable: true,
      valueSetter: (params) => {
        if (params.newValue !== params.data.room_name?.name) {
          params.data.room_name = {
            ...params.data.room_name,
            name: params.newValue,
            edit_col: true,
          };

          handleRoomUpdateLogic(params.data, data);

          return true;
        }
        return false;
      },
    },
    {
      headerName: columnsNames.observation.name,
      field: columnsNames.observation.value,
      editable: true,
      cellEditorParams: { type: "text", maxLength: 50 },
      ...(isDisplayHiddenColumns ? { flex: 1 } : { width: 200, minWidth: 250 }),
      cellStyle: { textAlign: "left" },
      filter: "agTextColumnFilter",
      filterParams: {
        maxNumConditions: 1,
      },
      sortable: false,
      suppressMovable: true,
      valueSetter: (params) => {
        if (params.newValue !== params.data.observation?.name) {
          params.data.observation = {
            ...params.data.observation,
            name: params.newValue,
            edit_col: true,
          };

          handleObservationUpdateLogic(params.data, data);

          return true;
        }
        return false;
      },
    },
    {
      headerComponent: (params) => (
        <CustomFilters
          header="Sample Type"
          testList={testListOptions.map((test) => test.name)}
          selectedOptions={selectedOptions}
          setSelectedOptions={setSelectedOptions}
        />
      ),
      headerName: columnsNames.sample_type.name,
      field: columnsNames.sample_type.value,
      editable: true,
      ...(isDisplayHiddenColumns ? { flex: 1 } : { width: 200, minWidth: 200 }),
      sortable: false,
      suppressMovable: true,
      cellEditor: CustomSelectEditor,
      cellEditorParams: {
        values: testListOptions,
      },
      valueGetter: (params) => params.data.test?.name || "",
      valueSetter: (params) => {
        const selectedTest = testListOptions.find(
          (test) => test.id === params.newValue.id
        );
        if (selectedTest) {
          params.data.test = {
            id: selectedTest.id,
            name: selectedTest.name,
            edit_col: true,
          };
          handleTestUpdateLogic(params.data, data, testListOptions);

          params.api.refreshCells({
            rowNodes: [params.node],
            columns: [columnsNames.sample_cost.value],
            force: true,
          });
          handleUpdateRow(params.data);
          return true;
        }
        return false;
      },
    },
    {
      headerName: columnsNames.sample_location.name,
      field: columnsNames.sample_location.value,
      editable: true,
      ...(isDisplayHiddenColumns ? { flex: 1 } : { width: 200, minWidth: 200 }),
      filter: false,
      sortable: false,
      suppressMovable: true,
      valueGetter: (params) => params.data.sample_info?.[0]?.value || "",
      valueSetter: (params) => {
        const sampleInfo = params.data.sample_info?.[0];
        if (sampleInfo) {
          if (params.newValue) {
            Object.assign(sampleInfo, {
              value: params.newValue,
              edit_col: true,
            });
          } else {
            // Clear the value when empty
            Object.assign(sampleInfo, { value: "", edit_col: true });
          }
          params.data.edit_row = true;
          return true;
        }
        return false;
      },
    },
    {
      headerName: columnsNames.lab_code.name,
      field: columnsNames.lab_code.value,
      editable: true,
      hide: isDisplayHiddenColumns,
      width: 120,
      filter: false,
      sortable: false,
      suppressMovable: true,
      valueGetter: (params) => params.data.sample_info?.[1]?.value || "",
      valueSetter: (params) => {
        const sampleInfo = params.data.sample_info?.[1];
        if (sampleInfo) {
          if (params.newValue) {
            Object.assign(sampleInfo, {
              value: params.newValue,
              edit_col: true,
            });
          } else {
            Object.assign(sampleInfo, { value: "", edit_col: true });
          }
          params.data.edit_row = true;
          return true;
        }
        return false;
      },
    },
    {
      headerComponent: (params) =>
        CustomHeader(params, handleHiddenColumn, isDisplayHiddenColumns),
      headerName: columnsNames.sample_cost.name,
      field: columnsNames.sample_cost.value,
      editable: true,
      width: 200,
      filter: false,
      sortable: false,
      suppressMovable: true,
      cellEditorParams: { type: "number", maxLength: 7 },
      cellRenderer: (params) => costFormatter(params.value),
      cellClassRules: {
        strikethrough: (params) => {
          const discountValue = params.data.discount?.name;
          return discountValue && discountValue !== "None";
        },
      },

      valueSetter: (params) => {
        const newValue = params.newValue || 0;
        if (params.newValue !== params.data.test_sample.cost) {
          params.data.test_sample = {
            ...params.data.test_sample,
            cost: newValue,
            edit_col: true,
          };
          handleUpdateRow(params.data);

          return true;
        }
        return false;
      },
    },
    {
      headerName: columnsNames.discount.name,
      field: columnsNames.discount.value,
      editable: true,
      hide: isDisplayHiddenColumns,
      cellEditor: CustomSelectEditor,
      cellEditorParams: {
        values: discountOptions,
      },
      width: 160,
      filter: false,
      sortable: false,
      suppressMovable: true,
      valueGetter: (params) => {
        return params.data.discount ? params.data.discount.name : "";
      },
      valueSetter: (params) => {
        const selectedDiscount = discountOptions.find((item) => {
          return item.name === params.newValue.name;
        });
        if (selectedDiscount) {
          params.data.discount = { ...selectedDiscount };
        }
        params.data = {
          ...params.data,
          edit_row: true,
        };
        params.api.refreshCells({
          rowNodes: [params.node],
          columns: [columnsNames.sample_cost.value],
          force: true,
        });
        if (params.data.status) {
          handleUpdateRow(params.data);
        }
        return true;
      },
    },
    {
      headerName: columnsNames.sample_reason.name,
      field: columnsNames.sample_reason.value,
      editable: true,
      hide: isDisplayHiddenColumns,
      cellEditor: CustomSelectEditor,
      cellEditorParams: {
        values: sampleReasonOptions,
      },
      valueGetter: (params) => {
        return params.data.sample_reason ? params.data.sample_reason.name : "";
      },
      valueSetter: (params) => {
        const selectedReason = sampleReasonOptions.find((item) => {
          return item.name === params.newValue.name;
        });
        if (selectedReason) {
          params.data.sample_reason = { ...selectedReason };
        }
        params.data = {
          ...params.data,
          edit_row: true,
        };

        return true;
      },
      width: 180,
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerName: columnsNames.sample_note.name,
      field: columnsNames.sample_note.value,
      editable: true,
      hide: isDisplayHiddenColumns,
      width: 200,
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
    {
      headerComponent: (params) =>
        CustomHeader(
          params,
          handleHiddenColumn,
          isDisplayHiddenColumns,
          "flag"
        ),
      headerName: columnsNames.client_approval.name,
      field: columnsNames.client_approval.value,
      editable: false,
      hide: isDisplayHiddenColumns,
      cellRenderer: (params) =>
        customCellForFlagFormatter(
          params.value,
          params.data,
          handleFlagSelection
        ),
      width: 100,
      filter: false,
      sortable: false,
      suppressMovable: true,
    },
  ];

  return columns;
};
