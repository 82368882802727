import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import "./styles.css";

import React from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const CustomTable = ({
  filteredData = [],
  data = [],
  columns = [],
  handleHeaderInfo = () => {},
}) => {
  console.log("filtered", filteredData);
  const onRowDragEnd = (event) => {
    const { api } = event;
    const visibleRows = [];

    api.forEachNode((node) => {
      visibleRows.push(node.data);
    });

    console.log("Dragged visible rows:", visibleRows);

    const updatedFullDataset = [...data];

    visibleRows.forEach((draggedRow, newIndex) => {
      const originalIndex = updatedFullDataset.findIndex(
        (row) => row.id === draggedRow.id
      );
      if (originalIndex !== -1) {
        updatedFullDataset.splice(originalIndex, 1);
        updatedFullDataset.splice(newIndex, 0, draggedRow);
      }
    });

    handleHeaderInfo({ key: "is_dragged", value: true }, updatedFullDataset);
  };

  const onFilterChanged = (params) => {
    params.api.forEachNode((node) => {
      node.setDataValue("drag", "");
    });

    params.api.refreshCells({
      columns: ["drag"],
      force: true,
    });
  };

  return (
    <div className="ag-theme-alpine custom-grid" style={{ width: "100%" }}>
      <AgGridReact
        className="ag-theme-alpine"
        rowData={filteredData}
        columnDefs={columns}
        defaultColDef={{
          resizable: false,
          sortable: false,
          filter: false,
        }}
        //
        enableClipboard={true} //
        enableRangeSelection={true}
        clipboardDelimiter={"\t"}
        copyHeadersToClipboard={true}
        //
        rowDragManaged={true}
        rowDragMultiRow={true}
        onFilterChanged={onFilterChanged}
        animateRows={true}
        singleClickEdit={true}
        stopEditingWhenCellsLoseFocus={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        onRowDragEnd={onRowDragEnd}
        domLayout="autoHeight"
        enableFillHandle={true}
        localeText={{ noRowsToShow: "No data available yet!" }}
        onGridReady={(params) => {
          params.api.refreshCells({ force: true });
        }}
      />
    </div>
  );
};

export default CustomTable;
