import React, { useEffect, useRef, useState } from "react";

const CustomSelectEditor = (props) => {
  const { value, stopEditing, values = [], api, node, column } = props;
  const dropdownRef = useRef(null);
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    if (dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, []);

  const handleChange = (event) => {
    const newItem = values.find((item) => item.name === event.target.value);
    if (newItem) {
      setSelectedValue(newItem.name);
      api.getRowNode(node.id).setDataValue(column.colId, newItem);
    }
    stopEditing();
  };

  return (
    <select
      ref={dropdownRef}
      value={selectedValue || ""}
      onChange={handleChange}
      style={{ width: "100%", height: "100%", border: "none", outline: "none" }}
    >
      {values.map((item) => (
        <option key={item.id} value={item.name}>
          {item.name}
        </option>
      ))}
    </select>
  );
};

export default CustomSelectEditor;
