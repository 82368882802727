/* eslint-disable react-hooks/exhaustive-deps */
import { faCamera, faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getBuContent,
  updateAccountBU,
  updateBuContent,
} from "../../../../services/bu-admin-service";
import { uploadToS3 } from "../../../../services/s3";
import DeleteBUModal from "../../../components/DeleteBUModal";
import {
  getErrorMsg,
  refreshProfile,
  showToast,
} from "../../../shared/helpers";
import { regexes } from "../../../shared/regexes";
import { fileSizeLimits, folders } from "../../../shared/validations";
import AdminContentEditor from "../../SuperAdmin/AdminTabs/AdminContent/AdminContentEditor";
function CompanyDetails() {
  const [loader, setloader] = useState(true);

  const [preview, setPreview] = useState(null);
  const [errors, setErrors] = useState();
  const [editEnabled, setEditEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [profileData, setprofileData] = useState();
  const [file_e, setFile_e] = useState("");
  const [file, setFile] = useState(null);
  const [disabledInput, setDisabledInput] = useState(false);
  const role = useSelector((state) => state.user.data.role);

  const dispatch = useDispatch();

  const [opendel, setopendel] = useState(false);
  const [reportTerms, setReportTerms] = useState("");
  const [recieptTerms, setRecieptTerms] = useState("");
  const [disableFields, setDisableFields] = useState(true);
  const setDisabledFieldsChild = (value) => {
    setDisableFields(value);
  };

  useEffect(() => {
    setIsLoading(true);
    Promise.allSettled([
      getBuContent("terms-of-receipt"),
      getBuContent("terms-of-report"),
    ])
      .then((res) => {
        console.log(res);
        if (res[0]?.status === "fulfilled") {
          setRecieptTerms(res[0]?.value?.data?.content);
        }
        if (res[1]?.status === "fulfilled") {
          setReportTerms(res[1]?.value?.data?.content);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    setDisabledFieldsChild && setDisabledFieldsChild(disabledInput);
  }, [disabledInput]);

  const data = useSelector((state) => state.user.data);
  useEffect(() => {
    data && setloader(false);
    data && setprofileData(data?.business_unit);
    data && setPreview(data?.business_unit.logo_url);

    console.log(data.business_unit, "usr data");
  }, [data]);

  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    console.log(file);
    if (file) {
      if (file.size > fileSizeLimits.avatarBuInspector) {
        setFile_e("Photo cannot be larger than 2MB");
      } else if (file.type !== "image/jpeg" && file.type !== "image/png") {
        setFile_e("Image format should be JPG or PNG");
      } else {
        setFile_e("");
        setPreview(URL.createObjectURL(file));
        setFile(file);
      }
    }
  };

  const handleSave = () => {
    setIsLoading(true);
    const updatedData = {
      ...profileData.address,
      ...profileData.social_links,
      company_name: profileData.company_name,
      company_phone: profileData.company_phone,
    };
    if (data?.business_unit.logo_url !== preview) {
      console.log("upload photo");
      if (data?.business_unit?.logo_url) {
        console.log("delete photo");
        //deleteFromS3(data?.business_unit?.logo_url);
      }
      uploadPhoto()
        .then((url) => {
          updatedData.logo_url = url;
          updateCall(updatedData);
        })
        .catch(() => showToast("Unable to upload photo."));
    } else {
      updateCall(updatedData);
    }
  };

  const uploadPhoto = () => {
    console.log("file", file);
    return new Promise((resolve, reject) => {
      if (file) {
        uploadToS3(file, folders.profile)
          .then((res) => {
            console.log(res);
            resolve(res.url);
          })
          .catch((err) => {
            reject(err);
          });
      } else {
        resolve("");
      }
    });
  };

  const updateCall = (data) => {
    updateAccountBU(data)
      .then((res) => {
        console.log(res);
        updateContent();
        setEditEnabled(false);
        refreshProfile(dispatch, role);
      })
      .catch((err) =>
        showToast(getErrorMsg(err) || "Unable to update Company Details.")
      )
      .finally(() => setIsLoading(false));
  };

  const updateContent = () => {
    Promise.allSettled([
      updateBuContent("terms-of-receipt", { content: recieptTerms }),
      updateBuContent("terms-of-report", { content: reportTerms }),
    ]).catch((err) => {
      showToast(getErrorMsg(err) || "Unable to update Terms and Conditions.");
    });
  };

  return (
    <>
      {loader || isLoading ? (
        <Spinner
          animation="border"
          variant="success"
          className="app-spinner"
          style={{ left: "10%" }}
        />
      ) : (
        <div className="main-content-area">
          <div className="d-flex justify-content-end">
            {!editEnabled ? (
              <button
                className="btn btn-primary"
                disabled={false}
                onClick={() => setEditEnabled(true)}
                id="edit_fee"
              >
                Edit Company Details <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            ) : (
              <div className="d-flex">
                <button
                  className="btn btn-primary"
                  disabled={isLoading}
                  onClick={handleSave}
                >
                  Save
                </button>
                <button
                  className="btn btn-light ml-2 low-priority"
                  onClick={() => {
                    setEditEnabled(false);
                  }}
                  disabled={isLoading}
                >
                  Cancel
                </button>
              </div>
            )}
          </div>
          <p className="TabsLabel d-flex">General Details</p>
          <div className="d-grid border-div">
            <div className="row">
              <div className="col-md-9">
                <div className="row ">
                  <div className="col-md-12">
                    <div className="float-label mt-2">
                      <input
                        className="input-field form-control"
                        type="text"
                        maxLength="100"
                        required
                        id={`company_name`}
                        value={profileData?.company_name}
                        disabled={!editEnabled}
                        onChange={(ev) => {
                          setprofileData({
                            ...profileData,
                            company_name: ev.target.value,
                          });
                        }}
                      />
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData?.company_name === ""
                          ? "Please Enter a Company Name."
                          : ""
                          ? errors?.[`name.${profileData?.company_name}`]
                          : errors?.[`name.${profileData?.company_name}`]}
                      </small>

                      <label
                        className={
                          editEnabled && profileData?.company_name === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`company_name`}
                      >
                        Company Name
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <div className="float-label mt-3">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          id={`company_phone`}
                          value={new AsYouType("US").input(
                            profileData.company_phone
                          )}
                          maxLength="16"
                          disabled={!editEnabled}
                          onChange={(ev) => {
                            let value = ev.target.value;
                            value = value.replace(regexes.phoneInput, "");
                            setprofileData({
                              ...profileData,
                              company_phone: value,
                            });
                          }}
                        />
                      </div>

                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData?.company_phone === ""
                          ? "Please Enter Company Phone Number."
                          : !regexes.phone.test(profileData.company_phone)
                          ? "Enter Valid Phone number"
                          : errors?.[`name.${profileData?.company_phone}`]
                          ? errors?.[`name.${profileData?.company_phone}`]
                          : ""}
                      </small>

                      <label
                        className={
                          editEnabled && profileData?.company_phone === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`company_phone`}
                      >
                        Company Phone
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="float-label mt-3 ">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          id={`company_email`}
                          maxLength="50"
                          value={profileData.company_email}
                          disabled={true}
                        />
                      </div>
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData?.company_email === ""
                          ? "Please Enter a company Name."
                          : editEnabled &&
                            !regexes.email.test(profileData.company_email)
                          ? "Enter Valid Email Address"
                          : errors?.[`name.${profileData?.company_email}`]
                          ? errors?.[`name.${profileData?.company_email}`]
                          : ""}
                      </small>

                      <label
                        className={
                          editEnabled && profileData?.company_email === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`company_email`}
                      >
                        Company Email
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="upload-avatar ml-4 mt-1">
                  <div className="image-area" style={{ borderRadius: 24 }}>
                    {!preview ? (
                      <span
                        className="upload-icon"
                        style={
                          preview
                            ? {
                                backgroundColor: "transparent",
                                borderRadius: 24,
                              }
                            : { backgroundColor: "#fff", borderRadius: 24 }
                        }
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </span>
                    ) : null}
                    <img
                      width="120"
                      className="avatar-lg"
                      style={{ borderRadius: 24 }}
                      src={preview}
                      alt=""
                    />

                    <input
                      id="upload_image"
                      type="file"
                      className="upload-image"
                      accept="image/jpeg,image/png"
                      onChange={(e) => {
                        fileChangedHandler(e);
                      }}
                      disabled={!editEnabled}
                    />
                  </div>
                  <p>Upload Company Photo (Optional)</p>
                  <small className="text-danger ml-1">
                    {file_e}
                    {errors?.image_url ? errors?.image_url[0] : ""}
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div className=" border-div mt-3 pb-3">
            <p className="TabsLabel d-flex">Address</p>

            <div className="row">
              <div className="col-md-11">
                <div className="row">
                  <div className="col-md-6">
                    <div className="float-label mt-3">
                      <input
                        className="input-field form-control"
                        type="text"
                        maxLength="100"
                        required
                        id={`street1`}
                        value={profileData.address.address1}
                        disabled={!editEnabled}
                        onChange={(ev) => {
                          setprofileData((state) => ({
                            ...state,
                            address: {
                              ...state.address,
                              street1: ev.target.value,
                              address1: ev.target.value,
                            },
                          }));
                        }}
                      />
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData.address.address1 === ""
                          ? "Please Enter a Street 1."
                          : ""
                          ? errors?.[`name.${profileData.address.address1}`]
                          : errors?.[`name.${profileData.address.address1}`]}
                      </small>
                      <label
                        className={
                          editEnabled && profileData.address.address1 === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`street1`}
                      >
                        Street 1
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="float-label mt-3">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          id={`street2`}
                          maxLength="100"
                          value={profileData?.address?.address2 || ""}
                          disabled={!editEnabled}
                          onChange={(ev) => {
                            setprofileData((state) => ({
                              ...state,
                              address: {
                                ...state.address,
                                street2: ev.target.value,
                                address2: ev.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled &&
                        (profileData.address.address2 == null ||
                          profileData.address.address2 === "")
                          ? ""
                          : ""
                          ? errors?.[`name.${profileData.address.address2}`]
                          : errors?.[`name.${profileData.address.address2}`]}
                      </small>

                      <label
                        className={
                          profileData.address.address2 === null ||
                          profileData.address.address2 === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`street2`}
                      >
                        Street 2
                      </label>
                    </div>
                  </div>

                  <div className="col-md-6 mt-3">
                    <div className="float-label">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          maxLength="50"
                          id={`city`}
                          value={profileData.address.city}
                          disabled={!editEnabled}
                          onChange={(ev) => {
                            setprofileData((state) => ({
                              ...state,
                              address: {
                                ...state.address,
                                city: ev.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData.address.city === ""
                          ? "Please Enter a City."
                          : ""
                          ? errors?.[`name.${profileData.address.city}`]
                          : errors?.[`name.${profileData.address.city}`]}
                      </small>

                      {/* <label className={`input-field`} htmlFor={`company_name`}> */}
                      <label
                        className={
                          editEnabled && profileData.address.city === ""
                            ? `Active`
                            : `Active input-field`
                        }
                        htmlFor={`city`}
                      >
                        City
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mt-3">
                    <div className="float-label">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          id={`state`}
                          maxLength="50"
                          value={profileData.address.state}
                          disabled={!editEnabled}
                          onChange={(ev) => {
                            setprofileData((state) => ({
                              ...state,
                              address: {
                                ...state.address,
                                state: ev.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData.address.state === ""
                          ? "Please Enter a State."
                          : ""
                          ? errors?.[`name.${profileData.address.state}`]
                          : errors?.[`name.${profileData.address.state}`]}
                      </small>

                      {/* <label className={`input-field`} htmlFor={`company_name`}> */}
                      <label
                        className={
                          editEnabled && profileData.address.state === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`state`}
                      >
                        State
                      </label>
                    </div>
                  </div>

                  <div className="col-md-3 mt-3">
                    <div className="float-label">
                      <div>
                        <input
                          className="auth-input-field-extra icon form-control"
                          type="text"
                          id={`zip_code`}
                          maxLength="50"
                          value={profileData.address.zipcode}
                          disabled={!editEnabled}
                          onChange={(ev) => {
                            setprofileData((state) => ({
                              ...state,
                              address: {
                                ...state.address,
                                zipcode: ev.target.value,
                              },
                            }));
                          }}
                        />
                      </div>
                      <small className={"text-danger ml-1 visible"}>
                        {editEnabled && profileData.address.zipcode === ""
                          ? "Please Enter a Zipcode."
                          : ""
                          ? errors?.[`name.${profileData.address.zipcode}`]
                          : errors?.[`name.${profileData.address.zipcode}`]}
                      </small>

                      {/* <label className={`input-field`} htmlFor={`company_name`}> */}
                      <label
                        className={
                          editEnabled && profileData.address.zipcode === ""
                            ? `input-field`
                            : `Active input-field`
                        }
                        htmlFor={`zipcode`}
                      >
                        Zipcode
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" border-div mt-3 pb-3">
            <p className="TabsLabel d-flex mb-3">Social media links</p>
            <div className="row pt-1">
              <div className="col-md-11">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            maxLength="100"
                            id="cpname55"
                            disabled={!editEnabled}
                            value={profileData?.social_links?.instagram ?? ""}
                            onChange={(e) => {
                              setprofileData((s) => ({
                                ...s,
                                social_links: {
                                  ...s.social_links,
                                  instagram: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            profileData?.social_links?.instagram
                              ? "Active "
                              : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Instagram URL (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.instagram ? errors?.instagram[0] : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            disabled={!editEnabled}
                            maxLength="100"
                            id="cpname66"
                            value={profileData?.social_links?.facebook ?? ""}
                            onChange={(e) => {
                              setprofileData((s) => ({
                                ...s,
                                social_links: {
                                  ...s.social_links,
                                  facebook: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            profileData?.social_links?.facebook ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Facebook URL (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.facebook ? errors?.facebook[0] : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            disabled={!editEnabled}
                            maxLength="100"
                            id="cpname77"
                            value={profileData?.social_links?.twitter ?? ""}
                            onChange={(e) => {
                              setprofileData((s) => ({
                                ...s,
                                social_links: {
                                  ...s.social_links,
                                  twitter: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            profileData?.social_links?.twitter ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Twitter URL (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.twitter ? errors?.twitter[0] : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            disabled={!editEnabled}
                            maxLength="100"
                            id="cpname88"
                            value={profileData?.social_links?.youtube ?? ""}
                            onChange={(e) => {
                              setprofileData((s) => ({
                                ...s,
                                social_links: {
                                  ...s.social_links,
                                  youtube: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            profileData?.social_links?.youtube ? "Active " : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Youtube URL (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.youtube ? errors?.youtube[0] : ""}
                      </small>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="float-label">
                        <div>
                          <input
                            className="input-field form-control"
                            type="text"
                            disabled={!editEnabled}
                            maxLength="100"
                            id="cpname88"
                            value={profileData?.social_links?.website_url ?? ""}
                            onChange={(e) => {
                              setprofileData((s) => ({
                                ...s,
                                social_links: {
                                  ...s.social_links,
                                  website_url: e.target.value,
                                },
                              }));
                            }}
                          />
                        </div>
                        <label
                          className={`${
                            profileData?.social_links?.website_url
                              ? "Active "
                              : ""
                          }input-field`}
                          htmlFor="cpname"
                        >
                          Company Website (Optional)
                        </label>
                      </div>
                      <small className="text-danger ml-1">
                        {errors?.website_url ? errors?.website_url[0] : ""}
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className=" border-div mt-3 pb-3">
            <p className="TabsLabel d-flex">Terms and Conditions</p>
            <div className="row">
              <div className="col-md-11">
                <p
                  className="TabsLabel d-flex"
                  style={{ fontWeight: "normal" }}
                >
                  Report Terms
                </p>
                <AdminContentEditor
                  text={reportTerms}
                  disabled={!editEnabled}
                  setDisabledFieldsChild={setDisabledFieldsChild}
                  onSave={(val) => {
                    setReportTerms(val);
                  }}
                  maxLength={20000}
                  BUEdit={true}
                  toolbar={true}
                />
              </div>
              <div className="col-md-11 mt-1">
                <p
                  className="TabsLabel d-flex"
                  style={{ fontWeight: "normal" }}
                >
                  Receipt Terms and Condition
                </p>
                <AdminContentEditor
                  text={recieptTerms}
                  disabled={!editEnabled}
                  setDisabledFieldsChild={setDisabledFieldsChild}
                  onSave={(val) => {
                    setRecieptTerms(val);
                  }}
                  maxLength={20000}
                  BUEdit={true}
                  toolbar={true}
                />
              </div>
            </div>
          </div>

          <div className=" mt-3 pb-3">
            <p className="TabsLabel d-flex">Delete</p>

            <button
              className="btn btn-primary  mt-3"
              onClick={() => {
                setopendel(true);
                console.log("object");
              }}
              id="delete_company_details"
              style={{ backgroundColor: "#B44A4A", borderColor: "#B44A4A" }}
            >
              Delete Account
            </button>

            <DeleteBUModal
              show={opendel}
              company_name={profileData?.company_name}
              onHide={() => setopendel(false)}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default CompanyDetails;
