import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "react-quill/dist/quill.snow.css";

const editorId = "#trumbowyg-editor";
const editorConfig = {
  btns: [
    ["fontsize"],
    ["undo", "redo"],
    ["formatting"],
    ["strong", "em", "del"],
    ["table"],
    ["link"],
    ["unorderedList", "orderedList"],
    ["removeformat"],
    ["fullscreen"],
  ],

  plugins: {
    fontsize: {
      sizeList: ["14px", "18px", "22px"],
    },
    table: {
      styler: "editor-table",
      rows: 5,
      columns: 5,
    },
    allowTagsFromPaste: {
      allowedTags: ["h4", "p", "br"],
    },
  },
};

export const getHtmlTextLength = (html) => {
  if (html) {
    try {
      // const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "").replace(/(style=".+?")/g, "").replace(/(class=".+?")/g, "");
      const strip_tags = html.replace(/<(?!br\s*\/?)[^>]+>/g, "");
      const strip_enter = strip_tags.replace(/<br\s*\/?>/g, " ");
      const strip_space = strip_enter.replace(/&nbsp;/gi, " ");
      //const strip_and = strip_space.replace(/&amp;/gi, "&");
      const html_to_text = strip_space;
      const total = html_to_text.length;
      return total;
    } catch (e) {
      console.log(e);
    }
  }
  return 0;
};

export const formats = [
  "bold",
  "italic",
  "strike",
  "script",
  "underline",
  "blockquote",
  "header",
  "indent",
  "list",
  "align",
];

const handleKeyDown = (e, maxLength) => {
  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  const { keyCode } = e;
  if (
    length > maxLength - 1 &&
    keyCode !== 8 &&
    keyCode !== 46 &&
    keyCode !== 37 &&
    keyCode !== 38 &&
    keyCode !== 39 &&
    keyCode !== 40
  ) {
    e.preventDefault();
  }
};

function copyListner(event, maxLength) {
  var pastedText = undefined;
  const clipboardData = event.clipboardData || window.clipboardData;
  if (clipboardData) {
    pastedText = clipboardData.getData("text/plain");
    // console.log(pastedText);
  }

  const html = window.$(editorId).trumbowyg("html");
  const length = getHtmlTextLength(html);
  if (pastedText?.length + length > maxLength) {
    console.log("not pasting", pastedText);
    event.preventDefault();
  } else {
    console.log("pasting", pastedText);
  }
}

export default function ReportEditor({ text, onSave, maxLength, onCancle }) {
  const [length, setLength] = useState(0);
  // const filered_Text = text?.replace(/(\r\n|\n|\r)/gm, " ");
  const filered_Text = text;

  // const filered_Text = text.replace(/(style=".+?")/g, "").replace(/(class=".+?")/g, "");
  useEffect(() => {
    try {
      console.log("adding event");
      window
        .$(editorId)
        .trumbowyg(editorConfig)
        .on("tbwinit", () => {
          console.log("tbwinit");
          window.$(editorId).trumbowyg("html", filered_Text);

          console.log("adding keydown event");
          window
            .$(editorId)
            .parent()
            .on("keydown", (e) => handleKeyDown(e, maxLength));
        })
        .on("tbwchange", () => {
          const html = window.$(editorId).trumbowyg("html");
          setLength(getHtmlTextLength(html));
        });
    } catch (e) {
      console.log(e);
    }

    return () => {
      console.log("removing all events");
      window.$(editorId).trumbowyg().off();
      window
        .$(editorId)
        .parent()
        .off("keydown", (e) => handleKeyDown(e, maxLength));
    };
  }, [text]);

  useEffect(() => {
    document.addEventListener("paste", (e) => copyListner(e, maxLength));
    return () => {
      document.removeEventListener("paste", (e) => copyListner(e, maxLength));
    };
  }, [maxLength, text]);

  return (
    <React.Fragment>
      <textarea id="trumbowyg-editor"></textarea>
      <div className="rightsideactions">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            width: "100%",
          }}
        >
          <div style={{ marginTop: "-5px" }}>
            <small></small>
            <small>
              {length}/{maxLength}
            </small>
          </div>

          <div className="d-flex mt-3">
            <Button
              id="cancel"
              className="text-secondary500 bg-transparent border-0"
              onClick={onCancle}
            >
              Cancel
            </Button>
            <button
              className="btn btn-primary"
              onClick={() => {
                onSave && onSave(window.$(editorId).trumbowyg("html"));
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
