export const discountList = [
  {
    id: "",
    name: "None",
  },
  {
    id: 2,
    name: "No Cost",
  },
];

export const sampleReasonList = [
  {
    id: "",
    name: "None",
  },
  {
    id: 2,
    name: "Critical",
  },
  {
    id: 3,
    name: "Investigate",
  },
  {
    id: 4,
    name: "D/D/S",
  },
  {
    id: 5,
    name: "CR/CC/HE",
  },
  {
    id: 6,
    name: "PRV",
  },
];

export const columnsNames = {
  id: {
    name: "ID",
    value: "id",
  },
  is_hidden: {
    name: "",
    value: "is_hidden",
  },
  status: {
    name: "",
    value: "status",
  },
  row_number: {
    name: "S.no",
    value: "row_number",
  },
  room: {
    name: "Room",
    value: "room_name.name",
  },
  observation: {
    name: "Observation",
    value: "observation.name",
  },
  sample_type: {
    name: "Sample Type",
    value: "test",
  },
  sample_location: {
    name: "Sample Location",
    value: "sample_info[0].value",
  },
  lab_code: {
    name: "Lab Code",
    value: "sample_info[1].value",
  },
  sample_cost: {
    name: "Sample Cost",
    value: "test_sample.cost",
  },
  discount: {
    name: "Discount",
    value: "discount",
  },
  sample_reason: {
    name: "Sample Reason",
    value: "sample_reason",
  },

  sample_note: {
    name: "Note",
    value: "sample_note",
  },

  flag_reason: {
    name: "Flag Reason",
    value: "flag_reason",
  },

  client_approval: {
    name: "Flag",
    value: "client_approved",
  },
};

export const dbEditKeys = {
  rowEdit: "edit_row",
  colEdit: "edit_col",
};

export const sampleInfoKeys = {
  SAMPLE_LOCATION: {
    id: "",
    name: "Sample Location",
    value: "",
  },
  LAB_CODE: {
    id: "",
    name: "Lab Code",
    value: "",
  },
};

export const discountKeys = {
  OTHER_DISCOUNT: "other_discount",
  SAMPLE_DISCOUNT: "sample_discount",
};

export const summaryKeysObject = {
  sampling_balance: {
    label: "Outstanding Balance",
    key: "sampling_balance",
    defaultValue: "0.00",
    displayTop: true,
  },
  inspection_fee_paid: {
    label: "Inspection Fee (paid)",
    key: "inspection_fee",
    defaultValue: "0.00",
    editable: true,
  },
  service_fee_additional: {
    label: "Service Fee (additional)",
    key: "service_fee",
    defaultValue: "0.00",
    editable: true,
  },
  total_sample_cost: {
    label: "Total Sample Cost",
    key: "total_sample_cost",
    defaultValue: "0.00",
  },
  shipping: { label: "Shipping Fee", key: "shipping", defaultValue: "0.00" },
  expedite_fees: {
    label: "Expedite Fees",
    key: "expediate_fees",
    defaultValue: "0.00",
  },
  total_discount: {
    label: "Total Discount",
    key: "total_discount",
    defaultValue: "0.00",
  },
  total_cost: {
    label: "Total Cost",
    key: "total_cost",
    isTotal: true,
    defaultValue: "0.00",
  },
};

export const financedData = [
  { name: "Down Payment", cost: 100.0 },
  { name: "Amount Financed", cost: 9960.0 },
];
export const sampleData = [
  { name: "Air", count: 145.0 },
  { name: "Swab", count: 145.0 },
  { name: "Bulk", count: 145.0 },
  { name: "ERMI/MSQPCR", count: 595.0 },
  { name: "EPA 36", count: 595.0 },
  { name: "FAB 3", count: 595.0 },
];
export const costData = [
  { name: "Mycotoxin", count: 700.0 },
  { name: "Endotoxin", count: 595.0 },
  { name: "Actinomycetes", count: 595.0 },
  { name: "Sewage Bacteria", count: 595.0 },
  { name: "VOC", count: 595.0 },
  { name: "Formaldehyde", count: 595.0 },
];

export const financingProgram = [
  { name: "Financial Length" },
  { name: "Monthly Payment", count: 830.0 },
];
