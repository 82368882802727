/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import { Nav, Navbar } from "react-bootstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { Link, NavLink } from "react-router-dom";
import LogoSidenav from "../../../assets/logo/logo_sidenav.png";
import routes, { authRoles } from "../../../navigation/route-config";
import { SET_SIDENAV } from "../../../store/actions";
import { permissions } from "../../shared/helpers";
import ProfileIcon from "../ProfileIcon/ProfileIcon";
import "./style.css";
import Submenu from "./SubMenu";

export default function SideNav() {
  const open = useSelector((state) => state.sidenav.open);
  const role = useSelector((state) => state.user.data.role);
  const userInfo = useSelector((state) => state.user.data);
  const accessibleRoutes = useSelector((state) => state.user.data.roles);

  const scrollBarRef = useRef(null);

  let availablePaths = null;
  if (
    role === authRoles.buSubAdmin ||
    (role === authRoles.buInspector &&
      (userInfo?.is_lead_inspector || userInfo?.is_consultant))
  ) {
    availablePaths = permissions.filter((pr) =>
      accessibleRoutes.some((ar) => ar.alias === pr.value)
    );
  }
  console.log("availablePaths", availablePaths);

  const navRoutes = routes.filter((item) => {
    if (
      role === authRoles.buSubAdmin ||
      (role === authRoles.buInspector &&
        (userInfo?.is_lead_inspector || userInfo?.is_consultant))
    ) {
      const availablePaths = permissions.filter((pr) =>
        accessibleRoutes.some((ar) => ar.alias === pr.value)
      );

      return (
        item.showInSideNav &&
        availablePaths.some((i) => item.path.startsWith(i.routePath))
      );
    } else {
      return item.showInSideNav && item.roles.includes(role);
    }
  });
  const dispatch = useDispatch();
  const md = useMediaQuery({ query: "(max-width: 992px)" });
  const lg = useMediaQuery({ query: "(min-width: 993px)" });
  const openSidenav = {
    width: "230px",
    opacity: 1,
    isFocused: true,
  };
  const closeSidenav = {
    marginLeft: "-230px",
    opacity: 0,
  };

  useEffect(() => {
    if (md) {
      dispatch({ type: SET_SIDENAV, payload: { open: false } });
    } else if (lg) {
      dispatch({ type: SET_SIDENAV, payload: { open: true } });
    }
  }, [md, lg]);

  return (
    <Navbar
      id="side-nav"
      style={open ? openSidenav : closeSidenav}
      tabIndex="-1"
      onBlur={() => {}}
    >
      <Link to="/" className="navbar-logo">
        <img
          src={LogoSidenav}
          width="190px"
          className="d-inline-block align-top"
          alt="Scout logo"
        />
      </Link>
      {role === authRoles.buAdmin &&
      !(
        userInfo?.first_name &&
        userInfo?.last_name &&
        userInfo?.isSubscribed
      ) ? (
        <ProfileIcon
          subscribed={false}
          style={{ height: "100%", justifyContent: "flex-end" }}
        />
      ) : (
        <>
          <div className="mt-2"></div>

          <div className="column" style={{ height: "100%" }}>
            <PerfectScrollbar ref={scrollBarRef}>
              <Nav className="column">
                {navRoutes.map((item, i) => (
                  <React.Fragment key={item.path}>
                    {item.hasChildren ? (
                      <Submenu data={item} />
                    ) : (
                      <NavLink
                        id={item.name}
                        key={item.path + item.name}
                        to={item.path}
                        exact={item.name === "Super Admin"}
                        style={{ color: "#84857a" }}
                        className="nav-link"
                        activeClassName="active"
                        activeStyle={{ color: "#2F481E" }}
                        onClick={() => {
                          setTimeout(() => {
                            scrollBarRef.current.updateScroll();
                          }, 500);
                        }}
                      >
                        {item.icon ? (
                          <img
                            src={
                              require(`../../../assets/icons/${item.icon}`)
                                .default
                            }
                            alt="icon"
                          />
                        ) : null}
                        {item.name}
                      </NavLink>
                    )}
                    {navRoutes[i + 1]?.name === "Settings" ? (
                      <hr style={{ width: "80%" }} />
                    ) : null}
                  </React.Fragment>
                ))}
              </Nav>
              <ProfileIcon />
            </PerfectScrollbar>
          </div>
        </>
      )}
    </Navbar>
  );
}
