import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faList } from "@fortawesome/free-solid-svg-icons";
import "./tabs.css";

export default function SlidingTabNavigation(props) {
  const { activeIndex, handleTabClick } = props;
  const icons = [faList, faEye, faEyeSlash];

  return (
    <div className="button-container">
      <div
        className="background"
        style={{
          transform:
            activeIndex !== null
              ? `translateX(${activeIndex * 100}%)`
              : "translateX(-100%)",
          opacity: activeIndex !== null ? 1 : 0,
        }}
      ></div>
      {["Show", "Hidden"].map((label, index) => (
        <button
          key={index}
          className={`button ${activeIndex === index ? "active" : ""}`}
          onClick={() => handleTabClick(index)}
        >
          <FontAwesomeIcon className="tab-icon" icon={icons[index]} />
          {label}
        </button>
      ))}
    </div>
  );
}
