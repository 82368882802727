import "./sample_strategy_styles.css";

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import {
  columnsNames,
  discountList,
  sampleReasonList,
} from "./utility/constant";
import { showToast } from "main/shared/helpers";
import {
  getInspectorListAction,
  getReportDataAction,
  getTestListAction,
  handleFlagSelectionAction,
  handleRowSelectionAction,
  handleAllSelectionAction,
  updateStrategyReportTableListAction,
  refreshReportDataAction,
  handleRowHideAction,
  getLeadInspectorListAction,
  getConsultantListAction,
  handleSortAction,
  handleRowUpdateAction,
  updateHeaderInfoAction,
  updateHeaderInfoDrag,
} from "store/actions/sample_strategy/sampleStrategyAction";
import { useDispatch, useSelector } from "react-redux";
import ActionButton from "./ActionButton/ActionButton";
import CustomTable from "main/components/Customtable/CustomTable";
import ReportHeaderInfo from "./ReportHeaderInfo/ReportHeaderInfo";
import Summary from "./Summary/Summary";
import { convertLabelIntoValueAndOption } from "main/shared/helpers";
import { getColumnsOfReportTable } from "./utility/helper";
import styles from "./sample_strategy.module.css";
import { useParams, useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import ConfirmationModal from "./Components/ConfirmationModal/ConfirmationModal";
import { profileRequest } from "services/auth-service";
import SlidingTabNavigation from "./utility/tabs/tabs";

const SampleStrategyReport = () => {
  const { insp_id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const sampleStrategyReport = useSelector(
    (state) => state.sampleStrategyReport
  );
  const {
    testList,
    testListLoading,
    inspectorListLoading,
    getReportLoading,
    reportData,
    saveReportDataLoading,
    publishReportDataLoading,
    refreshReportDataLoading,
  } = sampleStrategyReport;

  const [validationError, setValidationError] = useState(
    Object.keys(columnsNames).reduce((acc, key) => {
      acc[columnsNames[key].value] = {
        error: false,
        message: "",
      };
      return acc;
    }, {})
  );
  const [isDisplayHiddenColumns, setIsDisplayHiddenColumns] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectAllRows, setSelectAllRows] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [filteredData, setFilteredData] = useState();
  const testListOptions = JSON.parse(JSON.stringify(testList)) || [];
  const discountListOptions = JSON.parse(JSON.stringify(discountList)) || [];

  const sampleReasonListOptions =
    convertLabelIntoValueAndOption(sampleReasonList, "id", "name") || [];

  const handleAfterSaveCell = async (oldValue, newValue, row, column) => {
    try {
      const { dataField } = column;
      await dispatch(updateStrategyReportTableListAction(row, dataField));
    } catch (e) {
      console.log(e);
    }
  };

  const handleTabClick = (index) => {
    setActiveIndex(index);
  };

  const handleHiddenColumn = () => {
    setIsDisplayHiddenColumns(!isDisplayHiddenColumns);
  };

  const handleFlagSelection = (row) => {
    dispatch(handleFlagSelectionAction(row));
  };

  const handleSelectRow = (row) => {
    dispatch(handleRowSelectionAction(row));
  };

  const handleAllSelectRow = (e) => {
    if (activeIndex === 0) {
      dispatch(handleAllSelectionAction(e, setSelectAllRows));
    }
  };

  const handleUpdateRow = (row) => {
    dispatch(handleRowUpdateAction(row));
  };

  const handleHideRow = (row) => {
    dispatch(handleRowHideAction(row));
  };

  const handleHeaderInfo = (id, sample_rows) => {
    dispatch(updateHeaderInfoDrag(id, sample_rows));
  };

  const getReportData = async () => {
    try {
      await dispatch(getReportDataAction(insp_id));
    } catch (error) {
      console.log("error", error);
    }
  };

  const getTestList = async () => {
    try {
      await dispatch(getTestListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const getInspectorList = async () => {
    try {
      await dispatch(getInspectorListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const getLeadInspectorList = async () => {
    try {
      await dispatch(getLeadInspectorListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const getConsultantList = async () => {
    try {
      await dispatch(getConsultantListAction());
    } catch (error) {
      console.log("error", error);
    }
  };

  const isLoading = () => {
    return testListLoading || inspectorListLoading || getReportLoading;
  };

  const dbUpdateLoading = () => {
    return (
      saveReportDataLoading ||
      publishReportDataLoading ||
      refreshReportDataLoading
    );
  };

  const isSampleStrategyReportShown = async () => {
    try {
      const res = await profileRequest();
      const { show_sample_strategy } = res.data;
      return show_sample_strategy;
    } catch (err) {
      console.error(err.response);
      return false;
    }
  };

  useEffect(() => {
    getTestList();
    getInspectorList();
    getLeadInspectorList();
    getConsultantList();
    getReportData();
  }, []);

  useEffect(() => {
    const checkSampleStrategy = async () => {
      const result = await isSampleStrategyReportShown();
      if (!result) {
        history.goBack();
      }
    };
    checkSampleStrategy();
  }, []);

  useEffect(() => {
    const selectAll = reportData?.sample_strategy_report
      ?.filter((r) =>
        activeIndex === 0
          ? !r[columnsNames.is_hidden.value]
          : r[columnsNames.is_hidden.value]
      )
      .every((r) => r[columnsNames.status.value] === true);

    setSelectAllRows(selectAll);
  }, [reportData?.sample_strategy_report, activeIndex]);

  useEffect(() => {
    if (!reportData?.sample_strategy_report) return; // Exit if data is undefined
    const filteredrows = (reportData?.sample_strategy_report || []).filter(
      (row) =>
        (activeIndex === 0 ? !row?.is_hidden : row?.is_hidden) &&
        (selectedOptions.length === 0 ||
          selectedOptions.includes(row?.test?.name))
    );

    setFilteredData(filteredrows);
  }, [reportData?.sample_strategy_report, selectedOptions, activeIndex]);

  const handleRefresh = async () => {
    try {
      await dispatch(refreshReportDataAction(insp_id));
      showToast("Data Refreshed Successfully", true);
    } catch (e) {
      console.log(e);
      showToast(e?.message);
    }
  };

  return (
    <div className={styles.container}>
      {isLoading() ? (
        <div className={styles.loader_container}>
          <div className={styles.loader}></div>
        </div>
      ) : (
        <div className={`${styles.report_container}`}>
          {dbUpdateLoading() ? (
            <div className={styles.loader_overlay}>
              <div className={styles.loader}></div>
            </div>
          ) : (
            <></>
          )}
          <div className={styles.report_container_content}>
            <ActionButton />
            <ReportHeaderInfo />
            <div className={styles.reportTitle}>Recommended Sampling Plan</div>
            <SlidingTabNavigation
              activeIndex={activeIndex}
              handleTabClick={handleTabClick}
            />

            <div className={styles.table_container}>
              <CustomTable
                columns={getColumnsOfReportTable({
                  testListOptions,
                  isDisplayHiddenColumns,
                  handleHiddenColumn,
                  discountOptions: discountListOptions,
                  sampleReasonOptions: sampleReasonList,
                  handleFlagSelection: handleFlagSelection,
                  handleSelectRow: handleSelectRow,
                  selectAllRows: selectAllRows,
                  handleAllSelectRow: handleAllSelectRow,
                  handleHideRow: handleHideRow,
                  validationError,
                  setValidationError,
                  handleUpdateRow,
                  activeIndex: activeIndex,
                  selectedOptions,
                  setSelectedOptions,
                  data: reportData?.sample_strategy_report,
                })}
                filteredData={filteredData}
                data={reportData?.sample_strategy_report}
                activeIndex={activeIndex}
                handleHeaderInfo={handleHeaderInfo}
              />
            </div>
            <div className={styles.reportFooter}>
              <div className={styles.discountBtnCnt}>
                <Button
                  onClick={() => setShowConfirmation(true)}
                  className={styles.anchor_refresh_btn}
                  // size="sm"
                >
                  <img
                    src={require("../../../assets/icons/Refresh.png").default}
                    alt="Refresh"
                  />
                </Button>
              </div>
              <div className={styles.summary}>
                <Summary />
              </div>
            </div>
          </div>
        </div>
      )}

      <ConfirmationModal
        show={showConfirmation}
        onHide={() => setShowConfirmation(false)}
        msg={
          "Refreshing this document will discard all changes and revert it to its original state. Are you sure you want to refresh this document?"
        }
        onDelete={handleRefresh}
        yesBtnText={"Refresh"}
        noBtnText={"Cancel"}
        noBtnStyle
        hideOnPress
      />
    </div>
  );
};

export default SampleStrategyReport;
