import axios from "../axios-defaults";

const getInspectionList = (
  page = 1,
  type,
  search = "",
  date_from = "",
  date_to = ""
) => {
  return axios.get(
    `/api/v1/jobs?page=${page}&type=${type}&search=${search}&date_from=${date_from}&date_to=${date_to}`
  );
};
const getInspectionbyId = (id) => {
  return axios.get(`/api/v1/jobs/${id}`);
};

const updateInspectionbyId = (id, data) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(`/api/v1/jobs/${id}`, data);
};

const deleteInspectionbyId = (id, data) => {
  Object.assign(data, { _method: "patch" });
  return axios.post(`/api/v1/jobs/${id}`, data);
};
const getInspectors = (keyword, page = 1) => {
  return axios.get(
    `/api/v1/inspectors?${keyword ? `search=${keyword}&` : ""}page=${page}`
  );
};

const getAllInspectors = (keyword, page = 1) => {
  return axios.get(
    `/api/v1/lead-inspectors?${keyword ? `search=${keyword}&` : ""}page=${page}`
  );
};

const createJob = (data) => {
  return axios.post(`/api/v1/jobs`, data);
};

const addInspectionRoom = (data) => {
  return axios.post(`/api/v1/room`, data);
};

const editInspectionRoom = (id, data) => {
  return axios.post(`/api/v1/room/${id}`, {
    _method: "patch",
    ...data,
  });
};

const voidTransaction = (id) => {
  return axios.post(`/api/v1/inspection/transaction/${id}`, {
    _method: "patch",
    status: "void",
  });
};

const makeLeadByID = (inspectorId, inspectionId) => {
  return axios.post(`/api/v1/inspection/inspector/${inspectorId}`, {
    _method: "patch",
    inspection_id: inspectionId,
  });
};

export {
  getInspectionList,
  getInspectionbyId,
  updateInspectionbyId,
  deleteInspectionbyId,
  getInspectors,
  getAllInspectors,
  createJob,
  addInspectionRoom,
  editInspectionRoom,
  voidTransaction,
  makeLeadByID,
};
