import React, { useState, useRef } from "react";
import {
  Popper,
  Button,
  Typography,
  Checkbox,
  IconButton,
  FormControlLabel,
  TextField,
  withStyles,
  Paper,
  ClickAwayListener,
  Divider,
} from "@material-ui/core";
import "./filter_styles.css";
import ClearIcon from "@material-ui/icons/Clear";
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined";
import FilterListIcon from "@material-ui/icons/FilterList";

const CustomCheckbox = withStyles({
  root: {
    color: "#74b44a",
    "&$checked": {
      color: "#74b44a",
    },
  },
  checked: {},
})((props) => <Checkbox {...props} />);

const GreenTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#74b44a", // ✅ Label color when focused
    },
    "& .MuiOutlinedInput-root": {
      fontSize: "14px",
      height: "36px",
      "& fieldset": {
        borderColor: "#ccc", // ✅ Default border color
      },
      "&:hover fieldset": {
        borderColor: "#74b44a", // ✅ Green border on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "#74b44a", // ✅ Green border when focused
      },
    },
  },
})(TextField);

const createAnimatedButton = (bgColor, hoverColor) =>
  withStyles({
    root: {
      width: 40, // Initially small (only icon visible)
      height: 30,
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minWidth: "auto",
      padding: "5px 10px",
      backgroundColor: bgColor,
      transition: "width 0.3s ease-in-out, background-color 0.2s ease-in-out",
      "& .MuiButton-startIcon": {
        marginRight: "0 !important",
        marginLeft: 0,
      },
      "&:hover": {
        backgroundColor: hoverColor,
        width: 80, // Expands smoothly
      },
    },
  })(Button);

// Create Apply and Clear buttons with different colors
const ApplyButton = createAnimatedButton("#74b44a", "#699e46");
const ClearButton = createAnimatedButton("#d00505c4", "#d00505");

const StyledTypography = withStyles({
  root: {
    fontSize: 14,
    textTransform: "capitalize",
    marginLeft: 5,
    opacity: 0,
    display: "none",
    transition: "opacity 0.6s ease-in-out",
  },
})(({ classes, ...props }) => (
  <Typography
    {...props}
    className={classes.root}
    style={{
      opacity: 0,
      display: "none",
      transition: "opacity 0.6s ease-in-out",
    }}
  />
));

export default function CustomFilters({
  header,
  testList,
  selectedOptions,
  setSelectedOptions,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [tempSelectedOptions, setTempSelectedOptions] =
    useState(selectedOptions);
  const searchRef = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setTempSelectedOptions(selectedOptions);
    setTimeout(() => searchRef.current?.focus(), 100);
  };

  const handleClose = (event) => {
    if (anchorEl && anchorEl.contains(event.target)) return;
    setAnchorEl(null);
  };

  const handleToggle = (option) => {
    setTempSelectedOptions((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleSelectAll = () => {
    setTempSelectedOptions(
      tempSelectedOptions.length === testList.length ? [] : testList
    );
  };

  const handleApply = () => {
    setSelectedOptions(tempSelectedOptions);
    setAnchorEl(null);
  };

  const handleClear = () => {
    setTempSelectedOptions([]);
    setSelectedOptions([]);
  };

  const filteredList = testList.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Typography style={{ fontSize: 14, fontWeight: "bold" }}>
        {header}
      </Typography>

      <IconButton onClick={handleClick} color="primary">
        <FilterListIcon
          style={
            selectedOptions.length === 0
              ? { color: "#74b44a" }
              : { color: "#fff", backgroundColor: "#74b44a", borderRadius: 2 }
          }
        />
      </IconButton>

      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
        disablePortal={false} // ✅ Ensures Popper renders correctly
        modifiers={{
          preventOverflow: { enabled: true, boundariesElement: "window" },
          flip: { enabled: true },
        }}
        style={{ zIndex: 1300 }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              padding: 0,
              maxHeight: "350px", // ✅ Enable scrolling
              overflowY: "auto",
              width: "250px",
              position: "relative",
            }}
          >
            {/* Search Input */}
            <GreenTextField
              inputRef={searchRef}
              variant="outlined"
              size="small"
              placeholder="Search..."
              fullWidth
              style={{ padding: 10 }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              autoComplete="off"
            />

            <Typography
              variant="body1"
              style={{
                fontSize: 15,
                fontWeight: 700,
                marginBottom: 5,
                padding: "0 10px",
              }}
            >
              Select Filters
            </Typography>

            {/* Select All */}
            <FormControlLabel
              onClick={handleSelectAll}
              style={{ margin: 0, height: 25, marginBottom: 10 }}
              control={
                <CustomCheckbox
                  checked={tempSelectedOptions.length === testList.length}
                />
              }
              label="Select All"
            />
            <Divider />
            {/* Filtered Options */}
            <div
              className="filter-listing"
              style={{ flex: 1, overflowY: "auto", maxHeight: "300px" }}
            >
              {filteredList.length > 0 ? (
                filteredList.map((option) => (
                  <FormControlLabel
                    key={option}
                    style={{ width: "100%", margin: 0, height: 30 }}
                    onClick={() => handleToggle(option)}
                    control={
                      <CustomCheckbox
                        checked={tempSelectedOptions.includes(option)}
                      />
                    }
                    label={option}
                  />
                ))
              ) : (
                <Typography style={{ padding: "10px", textAlign: "center" }}>
                  No results found
                </Typography>
              )}
            </div>

            {/* Buttons Stick to Bottom */}
            <div
              style={{
                position: "sticky",
                bottom: 0,
                background: "white",
                paddingTop: "6px",
                boxShadow: "0 -2px 5px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  gap: "10px",
                  padding: "0px 10px 6px",
                }}
              >
                <ClearButton
                  className="filterClearBtn"
                  variant="contained"
                  color="secondary"
                  onClick={handleClear}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector("p").style.opacity = 1;
                    e.currentTarget.querySelector("p").style.display =
                      "inline-block";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector("p").style.opacity = 0;
                    e.currentTarget.querySelector("p").style.display = "none";
                  }}
                  startIcon={
                    <ClearIcon
                      style={{
                        marginRight: 0,
                        fontSize: 16,
                        color: "#fff",
                      }}
                    />
                  }
                >
                  <StyledTypography>Clear</StyledTypography>
                </ClearButton>
                <ApplyButton
                  variant="contained"
                  color="primary"
                  size="small"
                  className="filterAppluBtn"
                  onClick={handleApply}
                  onMouseEnter={(e) => {
                    e.currentTarget.querySelector("p").style.opacity = 1;
                    e.currentTarget.querySelector("p").style.display =
                      "inline-block";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.querySelector("p").style.opacity = 0;
                    e.currentTarget.querySelector("p").style.display = "none";
                  }}
                  startIcon={
                    <CheckOutlinedIcon
                      style={{
                        marginRight: "0 !important",
                        fontSize: 17,
                        color: "#fff",
                      }}
                    />
                  }
                >
                  <StyledTypography>Apply</StyledTypography>
                </ApplyButton>
              </div>
            </div>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </div>
  );
}
