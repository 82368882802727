import { discountList, summaryKeysObject } from "./constant";

export const calculateTotalSampleCost = (data = []) => {
  let totalSampleCost = 0;
  data.forEach((row) => {
    if (row.status) {
      totalSampleCost += Number(row.test_sample.cost);
    }
  });

  return totalSampleCost;
};

export const calculateTotalDiscountOnTable = (data = []) => {
  let totalDiscountCost = 0;
  data.forEach((row) => {
    if (row.status && row.discount.name === discountList[1].name) {
      totalDiscountCost += Number(row.test_sample.cost);
    }
  });
  return totalDiscountCost;
};

// (Sum of Service Fee, Total Sample Cost, Shipping, Expedite Fees) - Total Discount
export const calculateSamplingBalance = (summary) => {
  let samplingBalance = 0;
  const {
    service_fee,
    total_sample_cost,
    shipping,
    expediate_fees,
    total_discount,
  } = summary;
  samplingBalance = (
    Number(service_fee) +
    Number(total_sample_cost) +
    Number(shipping) +
    Number(expediate_fees) -
    Number(total_discount)
  ).toFixed(2);
  summary[summaryKeysObject.sampling_balance.key] = samplingBalance;
  return samplingBalance;
};
// (Sum of Inspection Fees, Service Fee, Total Sample Cost, Shipping Fees, Expedite fees) - Total Discount
export const calculateTotalCost = (summary) => {
  let totalCost = 0;
  const {
    inspection_fee,
    service_fee,
    total_sample_cost,
    shipping,
    expediate_fees,
    total_discount,
  } = summary;
  totalCost = (
    Number(inspection_fee) +
    Number(service_fee) +
    Number(total_sample_cost) +
    Number(shipping) +
    Number(expediate_fees) -
    Number(total_discount)
  ).toFixed(2);
  summary[summaryKeysObject.total_cost.key] = totalCost;
  return totalCost;
};
