/* eslint-disable react-hooks/exhaustive-deps */
import dayjs from "dayjs";
import produce from "immer";
import DatePicker from "main/components/DatePicker/DatePicker";
import TimePicker from "main/components/DatePicker/TimePicker";
import { authRoles } from "navigation/route-config";
import React, { useEffect, useState } from "react";
import ScrollBar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { getCustomers } from "../../../services/CustomerService";
import {
  createJob,
  getInspectors,
  getAllInspectors,
} from "../../../services/InspectionService";
import { getPropertyByCustomer } from "../../../services/PropertyService";
import CheckBoxModal from "../../components/CheckBox";
import ViewModal from "../../components/CheckBox/ViewModal";
import CustomInput from "../../components/Custom/CustomInput";
import { getAddress, getErrorMsg, showToast } from "../../shared/helpers";
import "./style.css";

const AddInspection = () => {
  const history = useHistory();

  //Customers
  const [customers, setCustomers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    getCustomers().then((res) => {
      console.log(res);
      setCustomers(res.data.data);
    });
  }, []);

  //propperty
  const [proplink, setproplink] = useState(false);
  const [proploader, setproploader] = useState(true);
  const [propdata, setpropdata] = useState(false);
  const [proppage, setproppage] = useState(1);

  const { customerId, customerName, propertyId, propertyAdd } = useParams();

  useEffect(() => {
    if (customerId && customerName && propertyId && propertyAdd) {
      setpay(
        // (state) => ({
        //   ...state,
        //   customer: customerId,
        //   property: propertyId,
        // })
        produce(pay, (draftState) => {
          draftState.customer = customerId;
          draftState.property = propertyId;
        })
      );
    }
  }, [customerId, customerName, propertyId, propertyAdd]);

  const propopen = () => {
    setpropdata(null);
    setproplink(true);
    setproploader(true);
    getPropertyByCustomer(pay.customer, proppage)
      .then(({ data: res }) => {
        setpropdata(
          (state) => ({
            ...res,
            data: state?.data ? [...state.data, ...res.data] : res.data,
          })
          // produce(propdata, draftState => {
          //   draftState = {
          //     ...res,
          //     data: propdata?.data ? [...propdata.data, ...res.data] : res.data
          //   }
          // console.log(draftState)
          // })
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setproploader(false));
  };

  //Inspectors
  const [inspectlink_view, setinspectlink_view] = useState(false);
  const [inspectloader_view, setinspectloader_view] = useState(true);
  const [inspectdata_view, setinspectdata_view] = useState(false);
  const [, setinspectpage_view] = useState(1);
  const inspectopen_view = () => {
    setinspectdata_view(null);
    setinspectlink_view(true);
    setinspectloader_view(false);
  };

  //Inspectors
  const [inspectlink, setinspectlink] = useState(false);
  const [inspectloader, setinspectloader] = useState(true);
  const [inspectdata, setinspectdata] = useState(false);
  const [inspectpage, setinspectpage] = useState(1);
  const inspectopen = () => {
    setinspectdata(null);
    setinspectlink(true);
    setinspectloader(true);
    getAllInspectors("", inspectpage)
      .then(({ data: res }) => {
        setinspectdata(
          (state) => ({
            ...res,
            data: state?.data ? [...state.data, ...res.data] : res.data,
          })
          // produce(inspectdata, draftState => {
          // console.log(res.data)
          //   draftState = { ...res, data: inspectdata?.data ? [...inspectdata.data, ...res.data] : res.data }
          // })
        );
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setinspectloader(false));
  };

  //main
  const [isSubmit, setisSubmit] = useState(false);
  const [errors, seterrors] = useState({});
  const [disbutton, setdisbutton] = useState(false);
  const user = useSelector((state) => state.user.data);
  const [pay, setpay] = useState({
    date: "",
    time: "",
    notes: "",
    scheduled_time: "",
    customer: "",
    property: "",
    inspectors: [],
  });

  const getSelectedInspectors = () => {
    const selectedInspectors =
      inspectdata?.data
        ?.filter((x) => pay.inspectors.includes(x.id))
        .map((x) => ({
          id: x.id,
          name: x.first_name + " " + x.last_name,
          image: x.inspector_details?.avatar_url,
        })) ?? [];
    if (user.role === authRoles.buInspector) {
      selectedInspectors.push({
        id: user.id,
        name: user.first_name + " " + user.last_name,
        image: user.inspector_details?.avatar_url,
      });
    }
    return selectedInspectors;
  };

  const getInspectorNames = () => {
    const inspectorNames =
      inspectdata?.data
        ?.filter((x) => pay.inspectors.includes(x.id))
        .map((xd) => {
          return `${xd.first_name + " " + xd.last_name}`;
        }) ?? [];
    if (user.role === authRoles.buInspector) {
      inspectorNames.push(user.first_name + " " + user.last_name);
    }

    return inspectorNames.join(", ");
  };

  useEffect(() => {
    if (user.role === authRoles.buInspector) {
      setpay((s) => ({ ...s, inspectors: [user.id] }));
    }
  }, [user]);

  const submit = () => {
    setisSubmit(true);
    if (
      pay.date &&
      pay.time &&
      pay.inspectors &&
      pay.property &&
      pay.notes &&
      pay.customer
    ) {
      setdisbutton(true);
      let data = {
        ...pay,
        scheduled_time:
          dayjs(pay.date).format("MM/DD/YY") +
          " " +
          dayjs(pay.time).format("HH:mm"),
      };
      delete data.date;
      delete data.time;

      createJob(data)
        .then((res) => {
          showToast("Inspection created successfully.", true);
          if (res?.data?.id) {
            history.push("/inspections/" + res.data.id);
          } else {
            history.push("/inspections");
          }
        })
        .catch((err) => {
          console.log(err?.response);
          if (err?.response?.status === 422) {
            seterrors(err?.response?.data);
          } else {
            showToast(getErrorMsg(err) || "Unable to create inspection.");
          }
        })
        .finally(() => {
          setdisbutton(false);
        });
    }
  };

  return (
    <div className="main">
      <ScrollBar style={{ height: "calc(100vh - 90px)" }}>
        <div className="main-content-area">
          <div className="d-flex align-items-center">
            <button
              onClick={submit}
              disabled={disbutton}
              className="btn btn-primary ml-auto"
            >
              Save
            </button>
          </div>
          <div>
            <h4>Appointment Details</h4>
            <div className="row">
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-6 " style={{ height: "54px" }}>
                    <DatePicker
                      value={pay.date}
                      setValue={(v) =>
                        setpay(
                          produce(pay, (draftState) => {
                            draftState.date = v;
                          })
                        )
                      }
                      label="Appointment Date"
                    />
                  </div>

                  <div className="col-md-6" style={{ height: "54px" }}>
                    <TimePicker
                      value={pay.time}
                      setValue={(v) =>
                        setpay(
                          produce(pay, (draftState) => {
                            draftState.time = v;
                          })
                        )
                      }
                      label="Appointment Time"
                    />
                    {/* <div className="form-group app-date">
                      <div className="float-label">
                        <i
                          className={`icon-picker  ${
                            timeType === "time" && "marginIcon"
                          } ${isFirefox && "marginIcon"} `}
                          style={{ fontSize: "19px" }}
                          aria-hidden="true"
                        >
                          <FontAwesomeIcon icon={faClock} color="#2F481E" />
                        </i>
                        <input
                          style={{ paddingTop: "11px" }}
                          className={`form-control auth-input-field-inspection dateclass ${
                            timeType === "time"
                              ? "placeholder-active"
                              : "placeholder-datenTime  placeholderclass"
                          }
                          ${
                            isFirefox && " placeholderclass"
                          }                                              
                          }                                              
                            }                                              
                          }                                              
                            }                                              
                          `}
                          type="time"
                          onBlur={() => pay.time === "" && settimeType("text")}
                          onClick={() => pay.time === "" && settimeType("time")}
                          data-initialized="true"
                          data-date-inline-picker="true"
                          value={pay.time}
                          onChange={(ev) =>
                            setpay(
                              // { ...pay, time: ev.target.value }
                              produce(pay, (draftState) => {
                                draftState.time = ev.target.value;
                              })
                            )
                          }
                        />
                        <label
                          className={`${
                            pay.time === "" && timeType === "text" && !isFirefox
                              ? "pl-5 activeTime"
                              : "Active pl-4"
                          }  auth-input-field `}
                        >
                          Appointment Time
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.time === ""
                          ? "Time cannot be empty"
                          : errors?.scheduled_time
                          ? errors?.scheduled_time[0]
                          : ""}
                      </small>
                    </div>
                   */}
                  </div>

                  <div className="col-md-12 mt-5">
                    <div className="form-group">
                      <div className="float-label">
                        <textarea
                          id="additional_remarks"
                          className="form-control input-field"
                          type="text"
                          maxLength="100"
                          value={pay.notes}
                          onChange={(e) =>
                            setpay(
                              // { ...pay, notes: e.target.value }
                              produce(pay, (draftState) => {
                                draftState.notes = e.target.value;
                              })
                            )
                          }
                          style={{ minHeight: "100px" }}
                        />
                        <label
                          className={`${pay.notes !== "" ? "Active " : ""} `}
                          htmlFor="stat"
                        >
                          Notes
                        </label>
                      </div>

                      <small className="text-danger ml-1">
                        {isSubmit && pay.notes === ""
                          ? "Notes cannot be empty"
                          : errors?.notes
                          ? errors?.notes[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h4>Link Customer</h4>
                    <div className="form-group">
                      {!customerId ? (
                        <>
                          <input
                            className="form-control auth-input-field-inspection"
                            style={{
                              cursor: "pointer",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            placeholder="Choose Customer"
                            value={
                              customerName ||
                              (pay.customer &&
                                // getAddress(
                                //   propdata?.data.find((x) => x.id === pay.property)
                                //     .address
                                // ))
                                `${
                                  customers?.filter(
                                    (x) => x.id === pay.customer
                                  )[0].first_name
                                } ${
                                  customers?.filter(
                                    (x) => x.id === pay.customer
                                  )[0].last_name
                                }`)
                            }
                            onClick={() => setShowModal(!showModal)}
                            type="text"
                            maxLength="15"
                          />
                          <CheckBoxModal
                            items={
                              //   propdata?.data?.map((x) => ({
                              //   id: x.id,
                              //   name: getAddress(x.address),
                              //   image: x.image?.url,
                              // }))
                              customers?.map((customer) => ({
                                id: customer.id,
                                name: `${customer.first_name} ${customer.last_name}`,
                              }))
                            }
                            onChecked={(items) => console.log(items)}
                            show={showModal}
                            isRadio={true}
                            onHide={() => setShowModal(false)}
                            isAvatar={false}
                            title={"Link Customer"}
                            showTittleIcon={false}
                            buttonLabel={"Link Customer"}
                            emptyText={"No Customer Available"}
                            value={[pay.customer]}
                            onSave={(ev) => {
                              if (ev.length > 0) {
                                setpay(
                                  // { ...pay, customer: ev[0] || "", property: "", }
                                  produce(pay, (draftState) => {
                                    draftState.customer = ev[0] || "";
                                    draftState.property = "";
                                  })
                                );
                              }
                            }}
                            applyPagination={true}
                            pagination={customers?.data}
                            onPageChange={(page) => {
                              setproppage(page);
                              propopen();
                            }}
                          />
                        </>
                      ) : (
                        <CustomInput
                          label="Linked Customer"
                          value={customerName}
                          inputProps={{ disabled: true }}
                        />
                      )}
                      <small className="text-danger ml-1">
                        {isSubmit && pay.customer === ""
                          ? "Customer cannot be empty"
                          : errors?.customer
                          ? errors?.customer[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h4>Link Property</h4>
                    <div className="form-group">
                      <input
                        className="form-control auth-input-field-inspection"
                        style={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        placeholder="Choose Linked Property"
                        value={
                          propertyAdd ||
                          (pay.property &&
                            getAddress(
                              propdata?.data.find((x) => x.id === pay.property)
                                .address
                            ))
                        }
                        disabled={!pay.customer}
                        type="text"
                        maxLength="15"
                        onClick={propopen}
                      />
                      <CheckBoxModal
                        items={propdata?.data?.map((x) => ({
                          id: x.id,
                          name: getAddress(x.address),
                          image: x.image?.url,
                        }))}
                        onChecked={(items) => console.log(items)}
                        show={proplink}
                        isRadio={true}
                        onHide={() => setproplink(false)}
                        isAvatar={true}
                        defaultAvatar={
                          require("../../../assets/icons/house.png").default
                        }
                        loader={proploader}
                        title={"Link Property"}
                        showTittleIcon={false}
                        buttonLabel={"Link Property"}
                        emptyText={"No Properties Available"}
                        value={[pay.property]}
                        onSave={(ev) => {
                          if (ev.length > 0) {
                            setpay(
                              // { ...pay, property: ev[0] }
                              produce(pay, (draftState) => {
                                draftState.property = ev[0];
                              })
                            );
                          }
                        }}
                        applyPagination={true}
                        pagination={propdata}
                        onPageChange={(page) => {
                          setproppage(page);
                          propopen();
                        }}
                      />
                      <small className="text-danger ml-1">
                        {isSubmit && pay.property === ""
                          ? "Property cannot be empty"
                          : errors?.property
                          ? errors?.property[0]
                          : ""}
                      </small>
                    </div>
                  </div>
                  <div className="col-md-8">
                    <h4>Inspectors on Site</h4>
                    <div className="form-group">
                      <input
                        className="form-control auth-input-field-inspection"
                        placeholder="Choose Inspector"
                        style={{
                          cursor: "pointer",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        value={getInspectorNames()}
                        onClick={inspectopen}
                        type="text"
                        maxLength="15"
                      />
                      <CheckBoxModal
                        items={inspectdata?.data?.map((x) => ({
                          id: x.id,
                          name: x.first_name + " " + x.last_name,
                          image: x.inspector_details?.avatar_url,
                        }))}
                        onChecked={(items) => console.log(items)}
                        show={inspectlink}
                        onHide={() => setinspectlink(false)}
                        isAvatar={true}
                        defaultAvatar={
                          require("../../../assets/icons/person.png").default
                        }
                        loader={inspectloader}
                        title={"Add Inspectors"}
                        showTittleIcon={false}
                        buttonLabel={"Save Inspectors"}
                        emptyText={"No Inspectors Available"}
                        value={pay.inspectors}
                        onSave={(ev) =>
                          setpay(
                            // { ...pay, inspectors: ev }
                            produce(pay, (draftState) => {
                              draftState.inspectors = ev;
                            })
                          )
                        }
                        applyPagination={true}
                        pagination={inspectdata}
                        onPageChange={(page) => {
                          setinspectpage(page);
                          inspectopen();
                        }}
                      />
                      {pay.inspectors.length > 0 ? (
                        <p></p>
                      ) : (
                        <small className="text-danger ml-1">
                          {isSubmit && pay.inspectors.length < 1
                            ? "Inspectors cannot be empty"
                            : errors?.inspectors
                            ? errors?.inspectors[0]
                            : ""}
                        </small>
                      )}

                      {pay.inspectors.length >= 1 && (
                        <>
                          <div className="d-flex justify-content-end">
                            <div
                              className="view-all-inspectors"
                              onClick={inspectopen_view}
                              style={{ cursor: "pointer" }}
                            >
                              view all
                            </div>
                          </div>
                          <ViewModal
                            items={getSelectedInspectors()}
                            onChecked={(items) => console.log(items)}
                            show={inspectlink_view}
                            onHide={() => setinspectlink_view(false)}
                            isAvatar={true}
                            defaultAvatar={
                              require("../../../assets/icons/person.png")
                                .default
                            }
                            loader={inspectloader_view}
                            title={"View Inspectors"}
                            showTittleIcon={false}
                            buttonLabel={"Close "}
                            applyPagination={true}
                            pagination={inspectdata_view}
                            onPageChange={(page) => {
                              setinspectpage_view(page);
                            }}
                            emptyText={"No Inspectors Available"}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

export default AddInspection;
