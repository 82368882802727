import React, { useState } from "react";
import { Popper, Typography } from "@material-ui/core";

const SnackBar = ({ event }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperText, setPopperText] = useState("Copy Table Data");
  const [openPopper, setOpenPopper] = useState(false);

  const handleMouseEnter = (event) => {
    setAnchorEl(event.currentTarget);
    setOpenPopper(true);
  };

  const handleMouseLeave = () => {
    setOpenPopper(false);
    setPopperText("Copy Table Data"); // Reset text when user leaves
  };

  const handleCopyAllRows = () => {
    if (!event?.api) {
      console.warn("Grid API is not available!");
      return;
    }

    let allRows = [];
    event.api.forEachNode((node) => {
      allRows.push(node.data);
    });

    if (allRows.length === 0) {
      console.warn("No rows available to copy!");
      return;
    }

    const requiredColumns = [
      { field: "row_number", getValue: (row) => row.row_number },
      { field: "room", getValue: (row) => row.room_name?.name || "" },
      { field: "observation", getValue: (row) => row.observation?.name || "" },
      { field: "sample_type", getValue: (row) => row.test?.name || "" },
      {
        field: "sample_location",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Sample Location")
            ?.value || "",
      },
      {
        field: "lab_code",
        getValue: (row) =>
          row.sample_info?.find((info) => info.name === "Lab Code")?.value ||
          "",
      },
      { field: "sample_cost", getValue: (row) => row.test_sample?.cost || "" },
      { field: "discount", getValue: (row) => row.discount?.name || "" },
      {
        field: "sample_reason",
        getValue: (row) => row.sample_reason?.name || "",
      },
      { field: "sample_note", getValue: (row) => row.sample_note || "" },
    ];

    const copiedData = allRows
      .map((row) =>
        requiredColumns.map(({ getValue }) => getValue(row)).join("\t")
      )
      .join("\n");

    console.log("Formatted Data:", copiedData);

    navigator.clipboard
      .writeText(copiedData)
      .then(() => {
        setPopperText("Copied! ✅");
        console.log("All data copied successfully!");
      })
      .catch((err) => console.error("Clipboard copy failed:", err));
  };

  return (
    <>
      <h6
        style={{
          fontWeight: 700,
          fontSize: 14,
          margin: "10px 0",
          cursor: "pointer",
        }}
        onMouseEnter={handleMouseEnter} // Show Popper on hover
        onMouseLeave={handleMouseLeave} // Hide on mouse leave
        onClick={handleCopyAllRows} // Copy data on click
      >
        S.No
      </h6>

      {/* Popper instead of Popover */}
      <Popper open={openPopper} anchorEl={anchorEl} placement="top-start">
        <div
          style={{
            padding: "8px",
            background: "#333",
            color: "#fff",
            borderRadius: "4px",
            fontSize: "12px",
            textAlign: "center",
          }}
        >
          <Typography variant="body2">{popperText}</Typography>
        </div>
      </Popper>

      {/* Snackbar UI */}
      {/* <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <SnackbarContent
          style={{ backgroundColor: "#4caf50", color: "#fff" }}
          message="Text Copied!"
        />
      </Snackbar> */}
    </>
  );
};

export default SnackBar;
